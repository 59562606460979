import { css } from "@emotion/css";
import { VFC } from "react";
import Fortune from "../Fortune";

const contentContainerClass = css`
  padding: 24px 16px;
`;
const notes = css`
  margin-top: 32px;
  font-size: 16px;
`;
const heading = css`
  border-left: solid 8px var(--background-color-primary);
  border-bottom: solid 1px var(--background-color-primary);
  padding: 4px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Top: VFC = () => {
  return (
    <div className={contentContainerClass}>
      <Fortune></Fortune>
      <div className={notes}>
        <h4 className={heading}>利用上の注意</h4>
        <div>本サイトはジョークアプリです。</div>
        <div>
          開発に際し、VTuber「月乃夜氷雨」様に名前及び画像の使用許諾をいただいています。
        </div>
        <br />
        <div>
          また、おみくじを引いて出た結果はランダム選出であり、いかなる個人情報も使用していません。
        </div>
        <div>
          本アプリを利用したことにより発生したトラブルの一切について、サイト運用者及びVTuber「月乃夜氷雨」はその責任を負いかねます。
        </div>
      </div>
    </div>
  );
};
export default Top;
