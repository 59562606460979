import { css } from "@emotion/css";
import { VFC } from "react";
import Button from "../components/Button";
import { FortuneRank, useFortune } from "./fortune";
import omikujiSrc from "./syougatsu2_omijikuji2.png";
import daikichiSrc from "./faces/daikichi.png";
import kichiSrc from "./faces/kichi.png";
import chukichiSrc from "./faces/chukichi.png";
import shoukichiSrc from "./faces/shoukichi.png";
import suekichiSrc from "./faces/suekichi.png";
import kyouSrc from "./faces/kyou.png";
import daikyouSrc from "./faces/daikyou.png";

const rankClass = css`
  font-weight: bold;
  font-family: "Yuji Syuku", serif;
  font-size: 84px;
  display: flex;
  justify-content: center;
  color: var(--background-color-primary);
`;

type FaceSetting = {
  rank: FortuneRank;
  src: string;
  faceMarginRight: number;
  popupMarginRight: number;
};
const faces: FaceSetting[] = [
  {
    rank: "大吉",
    src: daikichiSrc,
    faceMarginRight: 0,
    popupMarginRight: -16,
  },
  {
    rank: "吉",
    src: kichiSrc,
    faceMarginRight: 0,
    popupMarginRight: -16,
  },
  {
    rank: "中吉",
    src: chukichiSrc,
    faceMarginRight: 0,
    popupMarginRight: 1,
  },
  {
    rank: "小吉",
    src: shoukichiSrc,
    faceMarginRight: 0,
    popupMarginRight: -16,
  },
  {
    rank: "末吉",
    src: suekichiSrc,
    faceMarginRight: 0,
    popupMarginRight: 1,
  },
  {
    rank: "凶",
    src: kyouSrc,
    faceMarginRight: 0,
    popupMarginRight: 6,
  },
  {
    rank: "大凶",
    src: daikyouSrc,
    faceMarginRight: 0,
    popupMarginRight: 2,
  },
];
const messageContainer = (setting: FaceSetting) => css`
  border-radius: 4px;
  background: #efefef;
  padding: 8px;
  max-width: 512px;
  margin: 0 auto;
  font-family: "Zen Kurenaido", serif;
  font-size: 20px;
  position: relative;

  ::before {
    content: "▲";
    color: #efefef;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    width: 32px;
    position: absolute;
    top: -24px;
    left: 0;
    right: ${setting.popupMarginRight}px;
    margin: auto;
  }
`;
const faceContainer = css`
  width: 100%;
  height: 140px;
  margin: 16px 0 24px;
  display: flex;
  justify-content: center;
`;
const faceClass = (setting: FaceSetting) => css`
  width: 140px;
  height: 140px;
  right: ${setting.faceMarginRight}px;
  position: relative;
`;

const fortuneContainer = css`
  margin-bottom: 24px;
`;

const buttonContainer = css`
  display: flex;
  justify-content: center;
`;

const imageContainer = css`
  background-image: url(${omikujiSrc});
  width: 100%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 16px;
`;

const Fortune: VFC = () => {
  const { fortune, updateFortune } = useFortune();
  const faceSetting =
    faces.find((face) => face.rank === fortune?.rank) || faces[0];
  return (
    <div>
      {fortune ? (
        <div className={fortuneContainer}>
          <div className={rankClass}>{fortune.rank}</div>
          <div className={faceContainer}>
            <img
              src={faceSetting.src}
              alt="氷雨の顔画像"
              className={faceClass(faceSetting)}
            />
          </div>
          <div className={messageContainer(faceSetting)}>
            {fortune.fortunes.map((fortune) => (
              <div key={fortune.type}>
                {fortune.type}： {fortune.message}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={imageContainer}></div>
      )}
      <div className={buttonContainer}>
        <Button onClick={updateFortune}>おみくじを引く</Button>
      </div>
    </div>
  );
};
export default Fortune;
