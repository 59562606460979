import { css } from "@emotion/css";
import { VFC } from "react";
import { boxShadowNdp } from "../../functions/boxShadow";
import { joinClassNames } from "../../functions/joinClassNames";
import { mediaQueryInSP } from "../../functions/mediaQueries";
import iconSrc from "./S__44867774_white.png";

const headerClass = joinClassNames(css`
  width: 100%;
  height: 44px;
  background: var(--background-color-primary);
  ${boxShadowNdp(2)}

  display: flex;
  align-items: center;
  padding-left: 16px;

  color: #fff;
  font-size: 20px;
  font-weight: bold;

  ${mediaQueryInSP(`
    font-size: 28px;
    height: 72px;
  `)}
`);

const headerIconClass = css`
  height: 36px;
  width: 36px;
  margin-right: 8px;
`;

const Header: VFC = () => {
  return (
    <div className={headerClass}>
      <img src={iconSrc} className={headerIconClass} alt="ヘッダーアイコン" />
      月乃夜神社 御神籤
    </div>
  );
};
export default Header;
