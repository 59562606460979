import { useState } from "react";
import { chooseRandomlyFromArray as choice } from "../functions/chooseRandomlyFromArray";

export const fortuneTypes = ["恋愛", "失物", "金運", "学問"] as const;
export type FortuneType = typeof fortuneTypes[number];
type Fortune = {
  type: FortuneType;
  message: string;
};
export const fortuneRanks = [
  "大吉",
  "吉",
  "中吉",
  "小吉",
  "末吉",
  "凶",
  "大凶",
] as const;
export type FortuneRank = typeof fortuneRanks[number];
type FortuneCategory = {
  rank: FortuneRank;
  fortunes: Fortune[];
};

const fortunes: FortuneCategory[] = [
  {
    rank: "大吉",
    fortunes: [
      {
        type: "恋愛",
        message: "運命の人は意外と近くに",
      },
      {
        type: "失物",
        message: "必ず出てきます。",
      },
      {
        type: "金運",
        message: "宝くじが当たるかも",
      },
      {
        type: "学問",
        message: "真面目に取り組みましょう。夢は叶います。",
      },
    ],
  },
  {
    rank: "吉",
    fortunes: [
      {
        type: "恋愛",
        message: "今の縁を大事にすべし",
      },
      {
        type: "失物",
        message: "灯台下暗しです。",
      },
      {
        type: "金運",
        message: "募金するといいことが",
      },
      {
        type: "学問",
        message: "人に頼るのもよい事です。",
      },
    ],
  },
  {
    rank: "中吉",
    fortunes: [
      {
        type: "恋愛",
        message: "遠い所にも目を向けて",
      },
      {
        type: "失物",
        message: "時間はかかりますが出てくるでしょう",
      },
      {
        type: "金運",
        message: "お金大事です。",
      },
      {
        type: "学問",
        message: "誉ぽんっ🌸",
      },
    ],
  },
  {
    rank: "小吉",
    fortunes: [
      {
        type: "恋愛",
        message: "今一度、冷静に考えるべし",
      },
      {
        type: "失物",
        message: "実は、手の中にありませんか？",
      },
      {
        type: "金運",
        message: "甘い話には気を付けて",
      },
      {
        type: "学問",
        message: "油断は禁物",
      },
    ],
  },
  {
    rank: "末吉",
    fortunes: [
      {
        type: "恋愛",
        message: "誠意を尽くして接せよ",
      },
      {
        type: "失物",
        message: "諦めも大事です･･･。",
      },
      {
        type: "金運",
        message: "困る事はないでしょう。",
      },
      {
        type: "学問",
        message: "気を抜かず、頑張りましょう。",
      },
    ],
  },
  {
    rank: "凶",
    fortunes: [
      {
        type: "恋愛",
        message: "ニゲテ",
      },
      {
        type: "失物",
        message: "付喪神になりました。素晴らしい。",
      },
      {
        type: "金運",
        message: "課金はほどほどに",
      },
      {
        type: "学問",
        message: "鉛筆を転がしてみるのも手です。",
      },
    ],
  },
  {
    rank: "大凶",
    fortunes: [
      {
        type: "恋愛",
        message: "諦めも大事です。",
      },
      {
        type: "失物",
        message: "付喪神になりました。今は僕の眷属です。",
      },
      {
        type: "金運",
        message: "ギャンブルはほどほどに･･･",
      },
      {
        type: "学問",
        message: "来年がんばりましょう。",
      },
    ],
  },
];
const fortuneExists = (fortune: Fortune | undefined): fortune is Fortune =>
  !!fortune;

export const useFortune = () => {
  const [fortune, setFortune] = useState<FortuneCategory | null>(null);
  const updateFortune = () => {
    const index = Math.floor(Math.random() * fortunes.length);
    const category: FortuneCategory = {
      rank: fortunes[index].rank,
      fortunes: fortuneTypes
        .map((type) => {
          const fortuneIndex = choice(
            [index - 1, index, index + 1].filter(
              (i) => 0 <= i && i < fortunes.length
            )
          );
          return fortunes[fortuneIndex].fortunes.find((f) => f.type === type);
        })
        .filter(fortuneExists),
    };
    setFortune(category);
  };
  return {
    fortune,
    updateFortune,
  };
};
