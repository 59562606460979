import ExtensibleCustomError from "extensible-custom-error";

export class NoElementsToChoiceInArray extends ExtensibleCustomError {
  constructor(err?: Error) {
    super("配列は必ず1つ以上の要素を含んでください。", err);
  }
}

/**
 * 配列内から要素を一つランダムに出力する
 * @param options 配列
 * @returns 配列内のランダムな要素
 */
export const chooseRandomlyFromArray = <T extends any>(options: T[]) => {
  if (!options.length) {
    throw new NoElementsToChoiceInArray();
  }
  const index = Math.floor(Math.random() * options.length);
  return options[index];
};
