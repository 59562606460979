import { css } from "@emotion/css";
import { ButtonHTMLAttributes, VFC } from "react";
import { boxShadowNdp } from "../../functions/boxShadow";

const buttonClass = css`
  border: none;
  border-radius: 4px;
  font-size: 28px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  transition: background 300ms, box-shadow 300ms;
  color: #cf2126;
  border: 1px solid #ec4a48;
  ${boxShadowNdp(4)}
  &:active {
    ${boxShadowNdp(2)}
    background: #ffcacf;
    transition: background 50ms, box-shadow 50ms;
  }
`;

type Props = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "classNames" | "styles"
>;
const Button: VFC<Props> = ({ children, ...props }) => {
  return (
    <button className={buttonClass} {...props}>
      {children}
    </button>
  );
};
export default Button;
